import React, { Component } from 'react';
import { connect } from "react-redux";

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import{ 
  ToggleButtonGroup, 
  ToggleButton, 
  Typography} from '@mui/material'
import indicators from 'highcharts/indicators/indicators';
import ema from 'highcharts/indicators/ema';
import macd from 'highcharts/indicators/macd';
import stochastic from 'highcharts/indicators/stochastic';
import rsi from 'highcharts/indicators/rsi';
import bollingerBands from 'highcharts/indicators/bollinger-bands';
import chaikin from 'highcharts/indicators/chaikin';
import cci from 'highcharts/indicators/cci';
import HC_exporting from 'highcharts/modules/exporting';

import { MONTHS, CHART_TITLE } from '../../utils/const';

import { 
  addZerosToDecimalPlace, 
  formatVflow, 
  formatVFlowTicks,
  getTooltipText, 
  round } from '../../utils/helperFunctions';
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';
import DatePicker from 'react-date-picker';

import './SynchChart.css';

indicators(Highcharts);
ema(Highcharts)
macd(Highcharts);
rsi(Highcharts);
stochastic(Highcharts);
bollingerBands(Highcharts);
HC_exporting(Highcharts);
chaikin(Highcharts);
cci(Highcharts);

let tooltipRefresh = true;
let sixtyPercentVvol = 0;
let dateRangeText = [];

let zoomMax = '';
let zoomMin = '';

(function (H) {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  H.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    if (tooltipRefresh) {
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].removePlotBand('plot-line-1');
    }
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
  };

  H.syncExtremes = function (e) {
    var thisChart = this.chart;

    if (e && e.trigger !== "syncExtremes") {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart && chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            zoomMax = e.max;
            zoomMin = e.min;
            // It is null while updating
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
              trigger: "syncExtremes"
            });
          }
        }
      });
    }
    else if (!e && zoomMax !== '' && zoomMin !== '') {
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart && chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            // It is null while updating
            chart.xAxis[0].setExtremes(zoomMin, zoomMax, undefined, false, {
              trigger: "syncExtremes"
            });
          }
        }
      });
    }
  };

  /**
 * Show only one reset button for all charts
 * Reset button is shown only for first graph
 */
  H.wrap(H.Chart.prototype, 'showResetZoom', function (proceed) {
    var visibleBtn = false;

    Highcharts.each(Highcharts.charts, function (chart) {
      if (chart) {
        if (chart.resetZoomButton) {
          visibleBtn = true;
        }
      }
    });

    if (!visibleBtn) {
      //show reset button of chart
      // proceed.apply(Highcharts.charts[0], Array.prototype.slice.call(arguments, 1));
      //show custom reset button
      document.getElementById('reset-zoom-btn').style.visibility = 'visible'
    }
  });
})(Highcharts);

class SynchChart extends Component {

  constructor(props) {
    super(props)

    let options = [
      // { label: 'SMA', value: 'showSma' },
      // { label: 'EMA', value: 'showEma' },
      // { label: 'Bollinger Bands', value: 'showBB', classPrefix: 'bollingerBands' },
      // { label: 'MACD', value: 'showMacd', classPrefix: 'macd' },
      { label: "VVOL", value: 'showVVOL', classPrefix: 'vvol', index: 1},
      { label: "Daily VFLOW", value: 'showDailyVFLOW', classPrefix: 'vflow', index: 2},
      { label: "Indicator 3", value: 'showIndicator3', classPrefix: 'indicator3', index: 3},
      { label: "Indicator 5", value: 'showIndicator5', classPrefix: 'indicator5', index: 4},
      { label: "Indicator 6", value: 'showIndicator6', classPrefix: 'indicator6', index: 5},
      { label: 'RSI', value: 'showRsi', classPrefix: 'rsi' , index:6},
    ]

    if (props.rspData && props.rspData.length !== 0)
      options.push({ label: 'Relative Price', value: 'showRsp', classPrefix: "rsp", index: 7 })

    if (props.correlation && props.correlation.length !== 0)
      options.push({ label: 'Correlation', value: 'showCorrelation', classPrefix: "corr", index:8 })

    this.state = {
      data: null,
      date: null,
      showSma: false,
      showEma: false,
      showMacd: false,
      showBB: false,
      showRsi: false,
      showStochastic: false,
      showRsp: false,
      indicatorOptions: options,
      selectedValues: [],
      periodValue: 'Period',
      showCorrelation: false,
      chartDate: null,
      calendarOpen: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.data.closingprice && this.props.data.closingprice &&
      (nextProps.data.closingprice.length !== this.props.data.closingprice.length)) ||
      this.state.showRangeModal !== nextState.showRangeModal || nextProps.smaRange !== this.props.smaRange
      || this.state.showSma !== nextState.showSma || this.state.showEma !== nextState.showEma || this.state.macd !== nextState.showMacd
      || this.state.showBB !== nextState.showBB || this.state.showRsi !== nextState.showRsi || this.state.showRsp !== nextState.showRsp 
      || this.state.showCorrelation !== nextState.showCorrelation
  }

  componentDidMount() {
    ["mousemove", "touchmove", "touchstart"].forEach((eventType) => {
      let container = document.getElementById("synch-chart-container" + this.props.id);
      container.addEventListener(eventType, (e) => {
        var event;
        Highcharts.charts.forEach((chart, index) => {
          if (chart) {
            // Find coordinates within the chart
            let containerWidth = container.clientWidth + 30;

            event = chart.pointer.normalize(e);
            event.chartX = (event.chartX + (2 * containerWidth)) % containerWidth;
            chart.xAxis[0].removePlotLine('plot-line-1');
            // Get the hovered point
            chart.series.forEach(series => {
              const point = series.searchPoint(event, true);
              if (point) {
                try {
                  point.highlight(e);
                } catch (err) {
                  // pass;
                }
              }
            });
          }
        });
      });
    });

    ["mouseleave", "mouseout"].forEach((eventType) => {
      let container = document.getElementById("synch-chart-container" + this.props.id);
      container.addEventListener(eventType, (e) => {
        var event;
        Highcharts.charts.forEach((chart, index) => {
          if (chart) {
            chart.series.forEach(series => {
              event = chart.pointer.normalize(e);
              const point = series.searchPoint(event, true);
              if (point) {
                try {
                  point.onMouseOut();
                  chart.tooltip.hide(point);
                  chart.xAxis[0].hideCrosshair();
                } catch (err) {
                  // pass;
                }
              }
            });
          }
        });
      });
    });

    // console.log(this.props.portfolioAssetChartData)
  }

  onValueClick = () => {
    this.props.showRangeModal();
  }

  onRsiClick = () => {
    this.props.showRsiModal();
  }

  onFullView = () => {
    this.props.showChartInModal();
  }

  goBack = () => {
    this.props.goBack();
  }

  onValueSelect = (selectedList, selectedItem) => {
    let option = '';
    let action = selectedItem.action;

    if (action === 'clear') {
      this.setState({
        selectedValues: [],
        // showSma: false,
        // showEma: false,
        // showMacd: false,
        // showBB: false,
        showRsi: false,
        showStochastic: false,
        showRsp: false,
        showCorrelation: false,
        calendarOpen: false
      })
    }
    else {
      if (action === 'remove-value')
        option = selectedItem.removedValue.value;
      else
        option = selectedItem.option.value;

      this.setState({
        selectedValues: selectedList,
        [option]: action === 'remove-value' ? false : true
      }, () => {
        // console.log(this.state.selectedValues)
        Highcharts.syncExtremes();
      })
    }
  }

  onResetZoom() {
    document.getElementById('reset-zoom-btn').style.visibility = 'hidden';
    this.onPeriodClick('5', 'All');
  }

  onPeriodClick(value, text) {
    Highcharts.charts.forEach((chart) => {
      if (chart !== undefined) {
        if (chart.rangeSelector)
          chart.rangeSelector.clickButton(value, true);
      }
    })

    this.setState({
      periodValue: text
    })
  }

  onDateChange = (date) => {
    this.onResetZoom();
    let selectedDate = new Date(date).getTime();
    let closingPriceData = this.props.data.closingprice;
    let dateIndex = 0;
    closingPriceData.forEach((data, index) => {
      let currentDate = new Date(data.date).getTime()
      if (currentDate === selectedDate) {
        dateIndex = index;
      }
    })

    if (Highcharts.charts.length !== 0) {
      Highcharts.charts.forEach((chart, index) => {
        if (chart !== undefined) {
          chart.xAxis[0].update({
            crosshair: false,
            series: {
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          })
          chart.hoverSeries = chart.series[0];

          let selectedPoint = chart.hoverSeries.data[dateIndex];
          chart.tooltip.refresh([selectedPoint]);

          if (index === 0) {
            let closingPriceDiv = document.getElementById('closing-price-div-assetChartId');
            if (!closingPriceDiv)
              closingPriceDiv = document.getElementById('closing-price-div-assetChart');
            closingPriceDiv.innerHTML = 'Closing Price: ' + selectedPoint?.y?.toFixed(3) + ' ';
          }
          else if (index === 3) {
            let closingPriceDiv = document.getElementById('closing-price-div-familyChartId');
            if (!closingPriceDiv)
              closingPriceDiv = document.getElementById('closing-price-div-familyChart');
            closingPriceDiv.innerHTML = 'Closing Price: ' + selectedPoint?.y?.toFixed(3) + ' ';
          }

          let assetDateDiv = document.getElementById('series-dateassetChartId');
          if (!assetDateDiv)
            assetDateDiv = document.getElementById('series-dateassetChart');

          let familyDateDiv = document.getElementById('series-datefamilyChartId');
          if (!familyDateDiv)
            familyDateDiv = document.getElementById('series-datefamilyChart');
          if(familyDateDiv){
          let date = new Date(selectedPoint.x);
          assetDateDiv.innerHTML = MONTHS[date.getMonth()] + " " + String(date.getDate()) + ", " + String(date.getFullYear());
          familyDateDiv.innerHTML = MONTHS[date.getMonth()] + " " + String(date.getDate()) + ", " + String(date.getFullYear());
          }
          chart.xAxis[0].removePlotLine('plot-line-1');

          chart.xAxis[0].addPlotLine({
            value: selectedPoint.x,
            color: '#ccc',
            width: 1,
            id: 'plot-line-1'
          });

          chart.xAxis[0].update({
            crosshair: true,
            series: {
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          })
        }
      })

      this.setState({
        chartDate: selectedDate
      })
    }
  }

  render() {
    //todo add indicator 5 and 6 to props
    const { title, data, id, smaRange, showViewButton, rsiData, rsiRange, familyChartData, rspData, correlation, poiData } = this.props;
    const { showSma, showEma, showMacd, showBB, showRsi, indicatorOptions, showRsp, showCorrelation, calendarOpen } = this.state;

    tooltipRefresh = showViewButton;
    let newClosingPriceData = [];
    let newVVolData = [];
    let newVflowData = [];
    let newIndicator3 = []
    let newIndicator5 = []
    let newIndicator6 = []
    let closingPriceDataLength = data?.closingprice?.length;

    let maxDate = new Date();
    let minDate = new Date();

    if (closingPriceDataLength !== 0 && closingPriceDataLength !== undefined) {
      maxDate = new Date(data.closingprice[closingPriceDataLength - 1].date);
      minDate = new Date(data.closingprice[0].date);
    }

    //todo: Understand why we're doing this -> Due to it being in the render function this is necessary
    //  -> Data isn't always available on first render
    if (familyChartData && familyChartData?.closingprice) {
      if (familyChartData?.closingprice.length > data?.closingprice?.length) {
        familyChartData.closingprice.forEach((value) => {
          newClosingPriceData.push({ date: value.date, close: null })
          newVVolData.push({ date: value.date, vvol: null })
          newVflowData.push({ date: value.date, vflow: null })
        })
        familyChartData.indicator3.forEach((value) => {
          newIndicator3.push({date: value.date, indicator3: value.indicator3})
        })
        familyChartData.indicator5.forEach((value) => {
          newIndicator5.push({date: value.date, indicator5: value.indicator5})
        })
        familyChartData.indicator6.forEach((value) => {
          newIndicator6.push({date: value.date, indicator6: value.indicator6})
        })

        newClosingPriceData.forEach((value) => {
          data.closingprice.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.close = oldValue.close;
            }
          })
        })

        newVVolData.forEach((value) => {
          data.dailyvvol.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.vvol = oldValue.vvol;
            }
          })
        })

        newVflowData.forEach((value) => {
          data.dailyvflow.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.vflow = oldValue.vflow;
            }
          })
        })

        newIndicator3.forEach((value) => {
          data.indicator3.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.indicator3 = oldValue.indicator3;
            }
          })
        })

        newIndicator5.forEach((value) => {
          data.indicator5.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.indicator5 = oldValue.indicator5;
            }
          })
        })

        newIndicator6.forEach((value) => {
          data.indicator6.forEach((oldValue) => {
            if (oldValue.date === value.date) {
              value.indicator6 = oldValue.indicator6;
            }
          })
        })

        data.closingprice = newClosingPriceData;
        data.dailyvvol = newVVolData;
        data.dailyvflow = newVflowData;
        data.indicator3 = newIndicator3
        data.indicator5 = newIndicator5
        data.indicator6 = newIndicator6
      }
    }

    // to calculate 2/3 of the vvol range
    let vvolsArray = [];
    if (data.length !== 0) {
      data.dailyvvol.forEach((vvol) => {
        vvolsArray.push(vvol.vvol);
      })
      sixtyPercentVvol = ((Math.max(...vvolsArray) + Math.abs(Math.min(...vvolsArray))) * 2 / 3 + Math.min(...vvolsArray));
    }

    const vvols = data.length !== 0 ? data.dailyvvol
      .map(dateVVOLMap => {
        if (dateVVOLMap.vvol >= sixtyPercentVvol) {
          let date = new Date(dateVVOLMap.date).getTime();
          dateRangeText.push(date)
        }
        return isNaN(dateVVOLMap.vvol) ?
          [] :
          [new Date(dateVVOLMap.date).getTime(), dateVVOLMap.vvol]
      })
      .sort((a, b) => a[0] - b[0])
      : [];

    const closingprices = data.length !== 0 ? data.closingprice
      .map(dateClosingPriceMap => {
        let currentPoi = poiData && poiData.length !== 0 ? poiData.filter((poi) => {
          return new Date(poi.date).getTime() === new Date(dateClosingPriceMap.date).getTime()
        }) : [];
        if (currentPoi.length !== 0 && currentPoi[0].poi === 1) {
          return {
            x: new Date(dateClosingPriceMap.date).getTime(),
            y: dateClosingPriceMap.close,
            marker: {
              symbol: 'cross',
              color: "#FF0000",
              radius: 3,
              fillColor: '#f2a063'
            },
            turboThreshold: 0
          }
        } else
          return isNaN(dateClosingPriceMap.close) ?
            [] :
            [new Date(dateClosingPriceMap.date).getTime(), dateClosingPriceMap.close]
      })
      .sort((a, b) => a[0] - b[0])
      : [];

    const indicator3 = data.length !== 0 ? data.indicator3.map((point) => {
      return[new Date(point.date).getTime(), point.indicator3]
    }).sort((a, b) => a[0] - b[0]):[]

    const indicator5 = data.length !== 0 ? data.indicator5.map((point) => {
      // if(point.indicator5 === 0){
      //   return [new Date(point.date).getTime(), 0]
      // }else{
        
      //   return [new Date(point.date).getTime(), 1/point.indicator5]
      // }
      return[new Date(point.date).getTime(), point.indicator5]
    }).sort((a, b) => a[0] - b[0]):[]

    const indicator6 = data.length !== 0 ? data.indicator6.map((point) => {
      return[new Date(point.date).getTime(), point.indicator6]
    }).sort((a, b) => a[0] - b[0]):[]

    const rsiValues = rsiData ? rsiData[0]
      .map(dateClosingPriceMap => {
        return [dateClosingPriceMap.date, dateClosingPriceMap.value]
      })
      .sort((a, b) => a[0] - b[0])
      : [];

    let rspValues = [];
    let correlationValues = [];

    if (rspData && rspData.length !== 0) {
      rspValues = rspData ? rspData[0]
        .map(dateClosingPriceMap => {
          return [dateClosingPriceMap.date, dateClosingPriceMap.value]
        })
        .sort((a, b) => a[0] - b[0])
        : [];
    }

    const netDelta = data.length !== 0 ? data.dailyvflow
      .map(dateNetDeltaMap => {
        return isNaN(dateNetDeltaMap.vflow) ?
          [] :
          [new Date(dateNetDeltaMap.date).getTime(), dateNetDeltaMap.vflow]
      })
      .sort((a, b) => a[0] - b[0])
      : [];

    let maxClosingPrice = [];
    let minClosingPrice = [];
    // let maxClosingVvol = [];
    // let minClosingVvol = [];

    let rsiMax = rsiData ? rsiData[1] : 0;
    let rsiMin = rsiData ? rsiData[2] : 0;

    let rspMax = rspData ? rspData[1] : 0;
    let rspMin = rspData ? rspData[2] : 0;

    if (closingprices.length !== 0) {
      maxClosingPrice = closingprices.reduce((max, closingprice) => {
        if (max > closingprice[1]) {
          return max;
        } else {
          return closingprice[1];
        }
      }, closingprices[0][1]);

      minClosingPrice = closingprices.reduce((min, closingprice) => {
        if (min < closingprice[1]) {
          return min;
        } else {
          return closingprice[1];
        }
      }, closingprices[0]);
    }

    let maxIndicator3 = []
    let minIndicator3 = []
    // console.log(indicator3, closingprices)
    if(indicator3){
      maxIndicator3 = indicator3.reduce((max, indicator3) => {
        if (max > indicator3[1]) {
          return max;
        } else {
          return indicator3[1];
        }
      }, indicator3[0][1]);

      minIndicator3 = indicator3.reduce((min, indicator) => {
        // console.log(min, indicator)
        if (min < indicator[1]) {
          return min;
        } else {
          return indicator[1];
        }
      }, indicator3[0]);

      // console.log("indicator3 min", minIndicator3)
    }

    let maxIndicator5 = []
    let minIndicator5 = []
    // console.log(indicator5, closingprices)
    if(indicator5){
      maxIndicator5 = indicator5.reduce((max, indicator5) => {
        if (max > indicator5[1]) {
          return max;
        } else {
          return indicator5[1];
        }
      }, indicator5[0][1]);

      minIndicator5 = indicator5.reduce((min, indicator) => {
        // console.log(min, indicator)
        if (min < indicator[1]) {
          return min;
        } else {
          return indicator[1];
        }
      }, indicator5[0]);

      // console.log("indicator5 min", minIndicator5)
    }

    let maxIndicator6 = []
    let minIndicator6 = []
    // console.log(indicator6, closingprices)
    if(indicator6){
      maxIndicator6 = indicator6.reduce((max, indicator6) => {
        if (max > indicator6[1]) {
          return max;
        } else {
          return indicator6[1];
        }
      }, indicator6[0][1]);

      minIndicator6 = indicator6.reduce((min, indicator) => {
        // console.log(min, indicator)
        if (min < indicator[1]) {
          return min;
        } else {
          return indicator[1];
        }
      }, indicator6[0]);

      // console.log("indicator6 min", minIndicator6)
    }

    const maxVVOL = vvols.length !== 0 && vvols.reduce((max, vvol) => {
      if (max > vvol[1]) {
        return max;
      } else {
        return vvol[1];
      }
    }, vvols[0][1]);

    const minVVOL = vvols.length !== 0 && vvols.reduce((min, vvol) => {
      if (min < vvol[1]) {
        return min;
      } else {
        return vvol[1];
      }
    }, vvols[0][1]);

    let maxDelta = netDelta.length !== 0 && netDelta.reduce((max, delta) => {
      if (max > delta[1]) {
        return max;
      } else {
        return delta[1];
      }
    }, netDelta[0][1]);

    let minDelta = netDelta.length !== 0 && netDelta.reduce((min, delta) => {
      if (min < delta[1]) {
        return min;
      } else {
        return delta[1];
      }
    }, netDelta[0][1]);

    if (minDelta === null)
      minDelta = maxDelta - 1;

    if (minClosingPrice === null)
      minClosingPrice = maxClosingPrice - 1;

    const closingPriceA = Math.abs(maxClosingPrice - minClosingPrice) * 0.05;
    const minClosingPriceRange = minClosingPrice - closingPriceA;
    const maxClosingPriceRange = maxClosingPrice + closingPriceA;

    const rsiDelta = Math.abs(rsiMax - rsiMin) * 0.05;
    const minRsiRange = rsiMin - rsiDelta;
    const maxRsiRange = rsiMax + rsiDelta;

    let rspDelta = Math.abs(rspMax - rspMin) * 0.05;
    let minRspRange = rspMin - rspDelta;
    let maxRspRange = rspMax + rspDelta;

    if (minRspRange === maxRspRange) {
      minRspRange -= minRspRange;
      maxRspRange += rspMax;
    }

    if (correlation && correlation.length !== 0) {
      correlationValues = correlation ? correlation
        .map(dateClosingPriceMap => {
          return [dateClosingPriceMap.date, dateClosingPriceMap.value]
        })
        .sort((a, b) => a[0] - b[0])
        : [];
    }

    const vvolA = Math.abs(maxVVOL - minVVOL) * 0.05;
    const minVVOLRange = minVVOL - vvolA;
    const maxVVOLRange = maxVVOL + vvolA;

    const deltaA = Math.abs(maxDelta - minDelta) * 0.05;
    const calibratedMin = Math.min(minDelta - deltaA, 0);
    const calibratedMax = Math.max(maxDelta + deltaA, 0);

    let rsiChartData = [];
    let rspChartData = [];
    let correlationChartData = [];

    rsiChartData.push({
      name: 'RSI',
      data: rsiValues,
      visible: showRsi,
      lineWidth: 1,
      min: minRsiRange,
      max: maxRsiRange,
      color: '#f7edc1'
    })

    if (rspData)
      rspChartData.push({
        name: 'Relative Price',
        data: rspValues,
        visible: showRsp,
        lineWidth: 1,
        min: minRspRange,
        max: maxRspRange,
        color: '#f2a063'
      });

    if (correlation)
      correlationChartData.push({
        name: 'Correlation',
        data: correlationValues,
        visible: showCorrelation,
        lineWidth: 1,
        min: -1,
        max: 1,
        color: '#f28063'
      })

    let rsiOptions = {
      chart: {
        backgroundColor: '#37394d',
        height: '200px',
        zoomType: 'x',
        marginLeft: 60,

      },

      time: {
        useUTC: false
      },

      exporting: {
        enabled: false
      },

      title: {
        useHTML: true,
        text: `<h3 class="vista-chart-title"><span  style="color: #4b4d5e;">\u25CF </span> RSI </h3>`,
        align: 'left',
        style: {
          fontSize: "12px",
          color: 'white'
        }
      },

      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        labels: {
          formatter: function () {
            let date = new Date(this.value)
            return String(date.getDate()) + " TEST " + MONTHS[date.getMonth()] + " " + String(date.getFullYear()).slice(-2);
          },
          enabled: true,
          // rotation: "90"
          // style: {
          //   color: '#9ba2ab',
          // },
        },
        events: {
          setExtremes: function (e) {
            Highcharts.syncExtremes(e);
          }
        },
        crosshair: {
          snap: false
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        visible: true,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        title: {
          enabled: false,
          text: 'RSI'
        },
        labels: {
          style: {
            color: '#9ba2ab',
          },
        },
        min: minRsiRange,
        max: maxRsiRange,
        tickPositions: [0, 20, 80, 100],
        plotLines: [{
          value: 20,
          color: 'rgba(255, 5, 5, 0.4)',
          dashStyle: 'solid',
          width: 1
        }, {
          value: 80,
          color: 'rgba(60, 133, 4, 0.4)',
          dashStyle: 'Solid',
          width: 1
        }],
      },

      legend: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      series: rsiChartData,

      plotOptions: {
        area: {
          marker: {
            enabled: true,
            radius: 0
          }
        },
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          },
          point: {
            events: {
              mouseOver: function () {
                const point = { x: this.x, y: this.y };
                let rsiDiv = document.getElementById('rsi-div-' + id);

                rsiDiv.innerHTML = 'RSI (' + rsiRange + '): ' + point.y.toFixed(3);
                rsiDiv.style.display = 'block'
              }
            }
          },
          dataLabels: {
            allowOverlap: false,
            overflow: 'none',
            crop: false
          },
          turboThreshold: 5000,
          threshold: null,
          borderWidth: 0,
        }
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };

    let rspOptions = {
      chart: {
        backgroundColor: '#37394d',
        height: '200px',
        zoomType: 'x',
        marginLeft: 60
      },

      time: {
        useUTC: false
      },

      exporting: {
        enabled: false
      },

      title: {
        useHTML: true,
        text: `<h3 class="vista-chart-title"><span  style="color: #4b4d5e;">\u25CF </span> RSP (Relative Price) </h3>`,
        align: 'left',
        style: {
          fontSize: "12px",
          color: 'white'
        }
      },

      xAxis: {
        type: 'datetime',
        margin: 0,
        gridLineWidth: 1,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        labels: {
          formatter: function () {
            let date = new Date(this.value)
            return String(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + String(date.getFullYear()).slice(-2);
          },
          enabled: true,
          style: {
            color: '#9ba2ab',
          }
        },
        events: {
          setExtremes: function (e) {
            Highcharts.syncExtremes(e);
          }
        },
        crosshair: {
          snap: false
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        visible: true,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        title: {
          enabled: false,
          text: 'Relative Price'
        },
        labels: {
          style: {
            color: '#9ba2ab',
          },
        },
        min: minRspRange,
        max: maxRspRange,
        tickPositioner: (rspMax === rspMin) ? undefined : function () {
          let dataMax = this.dataMax;
          let dataMin = this.dataMin;

          const deltaA = Math.abs(dataMax - dataMin) * 0.05;
          let calibratedMin = parseFloat((dataMin - deltaA).toFixed(3));
          let calibratedMax = parseFloat(Math.max(dataMax + deltaA, 0).toFixed(3));
          if(dataMin > 0) calibratedMin = 0

          let positions = [];
          let increment = ((calibratedMax - calibratedMin) / 3);
          let tick = calibratedMin

          if (dataMax !== null && dataMin !== null) {
            for (tick; tick <= calibratedMax; tick += increment) {
              let tickValue = parseFloat(tick.toFixed(3));
              positions.push(tickValue);
            }
            let finalTick = parseFloat(tick.toFixed(3));
            positions.push(finalTick);
          }

          // if (dataMin === dataMax) {
            // let firstValue = tick - tick;
            // let secondValue = tick;
            // positions = [];
            // positions.push(firstValue, secondValue);
            // return positions
            // return undefined;
          // }
          // else {
            return positions;
          // }
        },
      },

      legend: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      series: rspChartData,

      plotOptions: {
        area: {
          marker: {
            enabled: true,
            radius: 0
          }
        },
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          },
          point: {
            events: {
              mouseOver: function () {
                const point = { x: this.x, y: this.y };
                let rspDiv = document.getElementById('rsp-div-' + id);

                rspDiv.innerHTML = 'Relative Price : ' + point.y.toFixed(3);
                rspDiv.style.display = 'block'
              }
            }
          },
          dataLabels: {
            allowOverlap: false,
            overflow: 'none',
            crop: false
          },
          turboThreshold: 5000,
          threshold: null,
          borderWidth: 0,
        }
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };

    let correlationOptions = {
      chart: {
        backgroundColor: '#37394d',
        height: '200px',
        zoomType: 'x',
        marginLeft: 60,

      },

      time: {
        useUTC: false
      },

      exporting: {
        enabled: false
      },

      title: {
        useHTML: true,
        text: `<h3 class="vista-chart-title"><span  style="color: #4b4d5e;">\u25CF </span> Correlation </h3>`,
        align: 'left',
        style: {
          fontSize: "12px",
          color: 'white'
        }
      },

      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        labels: {
          formatter: function () {
            let date = new Date(this.value)
            return String(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + String(date.getFullYear()).slice(-2);
          },
          enabled: true,
          style: {
            color: '#9ba2ab',
          }
        },
        events: {
          setExtremes: function (e) {
            Highcharts.syncExtremes(e);
          }
        },
        crosshair: {
          snap: false
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        visible: true,
        gridLineColor: '#4b4d5e',
        gridLineDashStyle: 'Dot',
        title: {
          enabled: false,
          text: 'Correlation'
        },
        labels: {
          style: {
            color: '#9ba2ab',
          },
        },
        min: -1,
        max: 1,
        tickPositions: [-1, 0, 1]
      },

      legend: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      series: correlationChartData,

      plotOptions: {
        area: {
          marker: {
            enabled: true,
            radius: 0
          }
        },
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          },
          point: {
            events: {
              mouseOver: function () {
                const point = { x: this.x, y: this.y };
                let corrDiv = document.getElementById('corr-div-' + id);

                corrDiv.innerHTML = 'Correlation (' + rsiRange + '): ' + point.y.toFixed(3);
                corrDiv.style.display = 'block'
              }
            }
          },
          dataLabels: {
            allowOverlap: false,
            overflow: 'none',
            crop: false
          },
          turboThreshold: 5000,
          threshold: null,
          borderWidth: 0,
        }
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };

    const allData = [
      {
        title: CHART_TITLE.CLOSING_PRICE,
        // height: showViewButton ? '280px' : '350px',
        height: '240px',
        arr: closingprices,
        type: "area",
        color: '#3dd49d',
        fillColor: 'rgba(61, 212, 157, 0.1)',
        min: minClosingPriceRange,
        max: maxClosingPriceRange,
        id: 'closingPrice',
        range: smaRange,
        smaColor: '#e971f0'
      },
      {
        title: CHART_TITLE.VVOL,
        height: '200px',
        arr: vvols,
        type: 'area',
        color: '#64b5f6',
        fillColor: '#64b5f638',
        min: minVVOLRange,
        max: maxVVOLRange,
        id: 'vvol',
        range: smaRange,
        smaColor: '#e971f1'
      },
      {
        title: CHART_TITLE.DAILY_VFLOW,
        height: '200px',
        type: 'area',
        arr: netDelta,
        color: '#80cbc4',
        fillColor: '#80cbc438',
        min: calibratedMin,
        max: calibratedMax,
        id: 'vflow',
        range: smaRange,
        smaColor: '#e971f2'
      },
      {
        //placeholder INDICATOR 3
        title: CHART_TITLE.INDICATOR_3,
        height: '200px',
        type: 'area',
        arr: indicator3,
        color: '#C03cde',
        fillColor: '#C03cde38',
        min: minIndicator3,
        max: maxIndicator3,
        id: 'indicator3',
        range: smaRange,
        smaColor: '#e971f3'
      },
      {
        title: CHART_TITLE.INDICATOR_5,
        height: '200px',
        type: 'area',
        arr: indicator5,
        color: '#fff59d',
        fillColor: '#fff59d38',
        min: minIndicator5,
        max: maxIndicator5,
        id: 'indicator5',
        range: smaRange,
        smaColor: '#e971f4'
      },
      {
        title: CHART_TITLE.INDICATOR_6,
        height: '200px',
        type: 'area',
        arr: indicator6,
        color: '#ffeb3b',
        fillColor: '#ffeb3b38',
        min: minIndicator6,
        max: maxIndicator6,
        id: 'indicator6',
        range: smaRange,
        smaColor: '#e971f5'
      }
    ];
    
    // console.log("allData", allData)
    const isChartData = allData.filter(data => data.arr && data.arr.length > 0).length === 6 ? true : false;

    let options = allData.map((data, index) => {
      let thisVal = this;
      let yAxisOptions = {};
      let exportingOptions = {};

      let series = [{
        name: data.title,
        data: data.max !== 0 ? data.arr : [],
        color: data.color,
        visible: true,
        lineWidth: 1,
        fillColor: data.fillColor || '',
        threshold: 0,
        type: data.type,
        id: data.id || '',
        states: {
          hover: {
            enabled: true
          },
          inactive: {
            opacity: 1
          }
        },
        marker: {
          radius: 1,
        }
      }];

      if (data.id) {
        exportingOptions = {
          enabled: false
        };

        if (showSma) {
          // TODO: Ask Eitan if sma makes sense for vvol
          if (data.id !== 'vvol')
            series.push({
              type: 'sma',
              linkedTo: data.id,
              params: {
                period: data.range
              },
              color: data.smaColor,
              visible: showSma
            });
        }

        if (showEma) {
          series.push({
            type: 'ema',
            visible: showEma,
            linkedTo: data.id,
            params: {
              period: 21
            },
            color: '#fa251e',
          })
        }

        if (showMacd && data.id === 'closingPrice') {
          series.push(
            {
              type: 'macd',
              visible: showMacd,
              linkedTo: data.id ? 'closingPrice' : '',
              color: '#7dadff',
              yAxis: data.id ? 1 : 0,
              params: {
                shortPeriod: 12,
                longPeriod: 26,
                signalPeriod: 9,
                period: 26
              },
              dataGrouping: {
                enabled: true,
              },
              threshold: 0,
              negativeColor: '#fff',
              macdLine: {
                styles: {
                  lineColor: '#ff7dcf'
                }
              },
              signalLine: {
                styles: {
                  lineColor: '#7dadff'
                }
              }
            }
          )
        }

        if (showBB && data.id === 'closingPrice') {
          series.push(
            {
              type: 'bb',
              visible: showBB,
              linkedTo: data.id ? 'closingPrice' : '',
              color: '#ffa94c',
              bottomLine: {
                styles: {
                  lineColor: '#ffdcb4'
                }
              },
              topLine: {
                styles: {
                  lineColor: '#ffe060'
                }
              }
            }
          )
        }

        if (showBB && data.id === 'vvol') {
          series.push(
            {
              type: 'bb',
              visible: showBB,
              linkedTo: data.id ? 'vvol' : '',
              color: '#64b5f6',
              bottomLine: {
                styles: {
                  lineColor: '#ffdcb4'
                }
              },
              topLine: {
                styles: {
                  lineColor: '#ffe060'
                }
              }
            }
          )
        }
      }
      else {
        exportingOptions = {
          enabled: false
        }
      }

      // TODO: What is this?
      // if (data.max) {
        yAxisOptions = {
          min: data.min,
          max: data.max,
          visible: true,
          alignTicks: false,
          startOnTick: true,
          endOnTick: true,
          gridLineColor: '#4b4d5e',
          gridLineDashStyle: 'Dot',
          title: {
            enabled: false,
            text: 'VVOL'
          },
          labels: {
            style: {
              color: '#9ba2ab',
              fontSize: "8px",
              textOverflow: 'none'
            },
            formatter: function () {
              if(Math.abs(this.value) < 0.1 && this.value !== 0 ) return this.value.toExponential(1)
              return formatVFlowTicks(this.value)
            }
          },
          tickPositioner: function () {
            let dataMax = this.dataMax;
            let dataMin = this.dataMin;
            if (dataMin > 0) dataMin = 0

            let positions = [],
              // tick = Math.floor(dataMin);
              tick = dataMin

            if (dataMin === dataMax) {
              return undefined;
            } else {
              // let increment = Math.ceil((dataMax - dataMin) / 3);
              let increment = (dataMax-dataMin)/3
              let diff = (dataMax - tick) * 0.05;
              if(dataMin === 0) {
                tick = dataMin
              }else{
                tick = dataMin - diff;
              }

              if (dataMax !== null && dataMin !== null) {
                for (tick; tick - increment <= dataMax; tick += increment) {
                  positions.push(tick);
                }
              }
              return positions;
            }
          },
          // height: data.id === 'closingPrice' ? '70%' : '100%'
          height: '100%'
        }

      if (data.id === 'closingPrice')
        yAxisOptions = [{
          min: data.min,
          max: data.max,
          visible: true,
          alignTicks: false,
          startOnTick: true,
          endOnTick: true,
          gridLineColor: '#4b4d5e',
          gridLineDashStyle: 'Dot',
          title: {
            enabled: false,
            text: 'Closing Price'
          },
          labels: {
            style: {
              color: '#9ba2ab',
              fontSize: "9px",
            },
            formatter: function () {
              return addZerosToDecimalPlace(round(this.value, 2), 2);
            }
          },
          tickPositioner: function () {
            let dataMax = this.dataMax;
            let dataMin = this.dataMin;

            let positions = [],
              // tick = Math.floor(dataMin);
              tick=dataMin

            if (dataMin === dataMax) {
              return undefined;
            }
            else {
              let increment = Math.ceil((dataMax - dataMin) / 3);
              let diff = (dataMax - tick) * 0.05;
              tick = dataMin - diff;

              if (dataMax !== null && dataMin !== null) {
                for (tick; tick - increment <= dataMax; tick += increment) {
                  positions.push(tick);
                }
              }
              return positions;
            }
          },
          // height: showMacd ? '50%' : '100%',
          // height: '40%'
        }
        // , {
        //   // height: '100%',
        //   top: '60%',
        //   min: 0,
        //   startOnTick: true,
        //   endOnTick: true,
        //   visible: true,
        //   alignTicks: false,
        //   labels: {
        //     style: {
        //       color: '#9ba2ab',
        //       fontSize: "9px",
        //     },
        //     x: 12
        //   },
        //   tickPositioner: function () {
        //     let dataMax = this.dataMax;
        //     let dataMin = this.dataMin;

        //     let positions = [],
        //       // tick = Math.floor(dataMin);
        //       tick = dataMin

        //     if (dataMin === dataMax) {
        //       if (dataMax > 0)
        //         positions.push(0, dataMax)
        //       else
        //         positions.push(dataMax, 0)

        //       return positions;
        //     }

        //     let diff = (dataMax - tick) * 0.05;
        //     tick = Math.floor(dataMin - diff);

        //     if (dataMax !== null && dataMin !== null) {
        //       positions.push(tick);
        //       positions.push(0);
        //       // let max = Math.ceil(dataMax);
        //       let max = dataMax
        //       positions.push(max);
        //     }
        //     return positions;
        //   }
        // }
      ]

      return {
        title: {
          useHTML: true,
          text: `<h3 class="vista-chart-title"><span  style="color: ${data.color};">\u25CF </span> ${data.title}</h3>`,
          align: 'left',
          style: {
            fontSize: "12px",
            color: 'white'
          }
        },

        chart: {
          borderColor: '#000000',
          backgroundColor: '#37394d',
          height: data.height,
          marginLeft: 60,
          isSynced: true,
          zoomType: 'x',
          alignAxes: true,
          resetZoomButton: {
            position: {
              x: 0,
              y: -35,
              align: 'right'
            },
            theme: {
              'stroke-width': 0.5,
              stroke: '#3bc796'
            }
          },
        },

        yAxis: yAxisOptions,

        rangeSelector: {
          enabled: data.id ? true : false,
          inputEnabled: false
        },

        navigator: {
          enabled: false
        },

        scrollbar: {
          enabled: false
        },

        tooltip: {
          enabled: showViewButton ? true : false,
          useHTML: showViewButton ? true : false,
          valueDecimals: 2,
          positioner: function () {
            return {
              // right aligned
              x: this.chart.chartWidth - this.label.width,
              y: 0 // align to title
            };
          },
          borderWidth: 0,
          backgroundColor: 'none',
          headerFormat: '',
          shadow: false,
          formatter: function () {
            var s = '';
            if (this.points) {
              this.points.forEach((point) => {
                let val = data.title !== CHART_TITLE.DAILY_VFLOW ?
                  point.y :
                  data.title === CHART_TITLE.DAILY_VFLOW ? formatVflow(point.y)
                    : ''
                if (data.id !== 'closingPrice')
                  s +=
                    '<div class="vvol-tooltip">' + data.title + ': ' +
                    val + '</div><br />';
              });
            }

            return s;
          },
          hideDelay: 0,
          shared: true,
          split: false,
        },

        xAxis: {
          type: 'datetime',
          gridLineWidth: 1,
          gridLineColor: '#4b4d5e',
          gridLineDashStyle: 'Dot',
          labels: {
            formatter: function () {
              let date = new Date(this.value);
              return String(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + String(date.getFullYear()).slice(-2);
            },
            enabled: true,
            style: {
              color: '#9ba2ab',
            },
          },
          events: {
            setExtremes: function (e) {
              Highcharts.syncExtremes(e);
            }
          },
          crosshair: {
            snap: false
          }
        },

        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },

        credits: {
          enabled: false
        },

        series: series,
        plotOptions: {
          macd: {
            zones: [{
              value: 0,
              color: '#b8f5da'
            }, {
              color: '#fbc0c2'
            }]
          },
          area: {
            marker: {
              enabled: true,
              radius: 0
            }
          },
          series: {
            point: {
              events: {
                mouseOver: function () {
                  const point = { x: this.x, y: this.y };

                  let dateDiv = document.getElementById('series-date' + id);
                  let date = new Date(point.x);
                  dateDiv.innerHTML = MONTHS[date.getUTCMonth()] + " " + String(date.getUTCDate()) + ", " + String(date.getUTCFullYear());

                  let closingPriceDiv = document.getElementById('closing-price-div-' + id);
                  let smaDiv = document.getElementById('sma-div-' + id);
                  let emaDiv = document.getElementById('ema-div-' + id);
                  let bbDiv = document.getElementById('bb-div-' + id);
                  let macdDiv = document.getElementById('macd-div-' + id);
                  let vvolDiv = document.getElementById('vvol-div-' + id);
                  let vvolSmaDiv = document.getElementById('vvol-sma-div-' + id);
                  let vvolEmaDiv = document.getElementById('vvol-ema-div-' + id);
                  let vflowDiv = document.getElementById('vflow-div-' + id);
                  let vflowSmaDiv = document.getElementById('vflow-sma-div-' + id);
                  let vflowEmaDiv = document.getElementById('vflow-ema-div-' + id);
                  let indicator3Div = document.getElementById('indicator3-div-' + id);
                  let indicator3SmaDiv = document.getElementById('indicator3-sma-div-' + id);
                  let indicator3EmaDiv = document.getElementById('indicator3-ema-div-' + id);
                  let indicator5Div = document.getElementById('indicator5-div-' + id);
                  let indicator5SmaDiv = document.getElementById('indicator5-sma-div-' + id);
                  let indicator5EmaDiv = document.getElementById('indicator5-ema-div-' + id);
                  let indicator6Div = document.getElementById('indicator6-div-' + id);
                  let indicator6SmaDiv = document.getElementById('indicator6-sma-div-' + id);
                  let indicator6EmaDiv = document.getElementById('indicator6-ema-div-' + id);

                  if (data.id) {
                    if (this.color === "#3dd49d") {
                      closingPriceDiv.innerHTML = 'Closing Price: ' + point.y.toFixed(3) + ' ';
                      closingPriceDiv.style.display = 'block';
                    }
                    if (this.color === '#64b5f6' && !showViewButton) {
                      vvolDiv.innerHTML = 'VVOL: ' + point.y.toFixed(3) + ' ';
                      vvolDiv.style.display = 'block';
                    }
                    if (this.color === '#80cbc4' && !showViewButton) {
                      vflowDiv.innerHTML = 'VFLOW: ' + formatVflow(point.y) + ' ';
                      vflowDiv.style.display = 'block';
                    }
                    //placeholder
                    if (this.color === '#C03cde' && !showViewButton) {
                      indicator3Div.innerHTML = 'Indicator 3: ' + point.y.toFixed(2) + ' ';
                      indicator3Div.style.display = 'block';
                    }
                    if (this.color === '#fff59d' && !showViewButton){
                      indicator5Div.innerHTML = 'Indicator 5: ' + point.y.toFixed(2) + ' ';
                      indicator5Div.style.display = 'block';
                    }
                    if (this.color === '#ffeb3b' && !showViewButton){
                      indicator6Div.innerHTML = 'Indicator 6: ' + point.y.toFixed(2) + ' ';
                      indicator6Div.style.display = 'block';
                    }
                    if (this.color === '#e971f0' && thisVal.state.showSma) {
                      smaDiv.innerHTML = ' | SMA (' + data.range + '): ' + point.y.toFixed(3) + '</span>';
                      smaDiv.style.display = 'block';
                    }
                    if (this.color === '#64b5f6' && thisVal.state.showSma) {
                      vvolSmaDiv.innerHTML = ' | SMA (' + data.range + '): ' + point.y.toFixed(3) + '</span>';
                      vvolSmaDiv.style.display = 'block';
                    }
                    if (this.color === '#80cbc4' && thisVal.state.showSma) {
                      vflowSmaDiv.innerHTML = ' | SMA (' + data.range + '): ' + formatVflow(point.y) + '</span>';
                      vflowSmaDiv.style.display = 'block';
                    }
                    if (this.color === '#C03cde' && thisVal.state.showSma) {
                      indicator3SmaDiv.innerHTML = ' | SMA (' + data.range + '): ' + point.y.toFixed(2) + '</span>';
                      indicator3SmaDiv.style.display = 'block';
                    }
                    if (this.color === '#fff59d' && thisVal.state.showSma) {
                      indicator5SmaDiv.innerHTML = ' | SMA (' + data.range + '): ' + point.y.toFixed(2) + '</span>';
                      indicator5SmaDiv.style.display = 'block';
                    }
                    if (this.color === '#ffeb3b' && thisVal.state.showSma) {
                      indicator6SmaDiv.innerHTML = ' | SMA (' + data.range + '): ' + point.y.toFixed(2) + '</span>';
                      indicator6SmaDiv.style.display = 'block';
                    }
                    else if (!thisVal.state.showSma && !showViewButton) {
                      // smaDiv.innerHTML = '';
                      // vvolSmaDiv.innerHTML = '';
                      // vflowSmaDiv.innerHTML = '';
                      // smaDiv.style.display = 'none';
                    }

                    if (this.color === "#3dd49d" && thisVal.state.showEma) {
                      let closingPriceValue = closingPriceDiv.innerHTML;
                      closingPriceDiv.innerHTML = closingPriceValue +
                        '<span class="ema-text indicator-value" id="ema-div-' + id + '"> | EMA (21): '
                        + point.y.toFixed(3) + ' </span>';
                    }
                    if (this.color === '#e971f0' && thisVal.state.showEma) {
                      emaDiv.innerHTML = ' | EMA (21): ' + point.y.toFixed(3) + '</span>';
                      emaDiv.style.display = 'block';
                    }
                    if (this.color === '#64b5f6' && thisVal.state.showEma) {
                      vvolEmaDiv.innerHTML = ' | EMA (21): ' + point.y.toFixed(3) + '</span>';
                      vvolEmaDiv.style.display = 'block';
                    }
                    if (this.color === '#80cbc4' && thisVal.state.showEma) {
                      vflowEmaDiv.innerHTML = ' | EMA (21): ' + formatVflow(point.y) + '</span>';
                      vflowEmaDiv.style.display = 'block';
                    }
                    if (this.color === '#C03cde' && thisVal.state.showEma) {
                      indicator3EmaDiv.innerHTML = ' | EMA (21): ' + point.y.toFixed(2) + '</span>';
                      indicator3EmaDiv.style.display = 'block';
                    }
                    if (this.color === '#fff59d' && thisVal.state.showEma) {
                      indicator5EmaDiv.innerHTML = ' | EMA (21): ' + point.y.toFixed(2) + '</span>';
                      indicator5EmaDiv.style.display = 'block';
                    }
                    if (this.color === '#ffeb3b' && thisVal.state.showEma) {
                      indicator6EmaDiv.innerHTML = ' | EMA (21): ' + point.y.toFixed(2) + '</span>';
                      indicator6EmaDiv.style.display = 'block';
                    }
                    else if (!thisVal.state.showEma && emaDiv) {
                      emaDiv.innerHTML = '';
                      emaDiv.style.display = 'none';
                    }

                    if (this.zone && this.MACD && this.signal && thisVal.state.showMacd) {
                      let closingPriceValue = closingPriceDiv.innerHTML;
                      closingPriceDiv.innerHTML = closingPriceValue +
                        '<span class="macd-text indicator-value" id="macd-div-' + id + '"> | MACD: ' +
                        point.y.toFixed(3) + ' <span class="macd-line">' +
                        this.MACD.toFixed(3) + '</span><span class="signal-line"> ' +
                        this.signal.toFixed(3) + '</span></span>';
                    }
                    else if (!thisVal.state.showMacd && macdDiv) {
                      macdDiv.innerHTML = '';
                      macdDiv.style.display = 'none';
                    }

                    if (this.color === '#ffa94c' && thisVal.state.showBB) {
                      // console.log("closingPrice vals -> ", this.top, this.middle, this.bottom)
                      let top = this.top;
                      let middle = this.middle;
                      let bottom = this.bottom;
                      let closingPriceValue = closingPriceDiv.innerHTML;
                      closingPriceDiv.innerHTML = closingPriceValue +
                        '<span class="bb-text indicator-value" id="bb-div-' + id + '">  | BB: <span class="top-color">' +
                        top.toFixed(3) + '</span> <span class="middle-color">' +
                        middle.toFixed(3) + '</span><span class="bottom-color"> ' +
                        bottom.toFixed(3) + '</span></span>';
                    } else if (this.color === '#64b5f6' && thisVal.state.showBB){
                      // console.log("vvol vals -> ", this.top, this.middle, this.bottom)
                      let top = this.top;
                      let middle = this.middle;
                      let bottom = this.bottom;
                      let vvolValue = vvolDiv.innerHTML;
                      vvolDiv.innerHTML = vvolValue +
                        '<span class="bb-text indicator-value" id="bb-div-' + id + '">  | BB: <span class="top-color">' +
                        top.toFixed(3) + '</span> <span class="middle-color">' +
                        middle.toFixed(3) + '</span><span class="bottom-color"> ' +
                        bottom.toFixed(3) + '</span></span>';
                    } else if (!thisVal.state.showBB && bbDiv) {
                      bbDiv.innerHTML = '';
                      bbDiv.style.display = 'none';
                    }
                    // 
                  }
                }
              }
            },
            dataLabels: {
              allowOverlap: false,
              overflow: 'none',
              crop: false
            },
            turboThreshold: 5000,
            threshold: 0,
            borderWidth: 0
          }
        },

        exporting: exportingOptions,

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      }
    });

    options.push(rsiOptions)
    options.push(rspOptions)
    options.push(correlationOptions)

    // Red lines
    const dailyVflowOption = options.filter(option => option.title.text.includes(CHART_TITLE.DAILY_VFLOW))[0];

    if (dailyVflowOption) {
      dailyVflowOption.yAxis['plotLines'] = [{
        value: 0,
        color: 'red',
        dashStyle: 'solid',
        width: 1
      }];
    }
    
    const indicator3Option = options.filter(option => option.title.text.includes(CHART_TITLE.INDICATOR_3))[0];

    if(indicator3Option) {
      indicator3Option.yAxis['plotLines'] = [{
        value: 0,
        color: 'red',
        dashStyle: 'solid',
        width: 1
      }];
    }
    
    const chartView = isChartData ?
      <>
        <div className="p-l-20 p-r-20 p-b-20 p-t-20" id="closing-price-chart-div">

          <div className="closing-price-text-div">
            <div className="graph-value-div">
              <div className="tooltipx">
                <div className="closing-price-text graph-value" id={"closing-price-div-" + id}>Closing Price</div>
                <div className="tooltiptext-div">{getTooltipText("closingPrice")}</div>
              </div>
              {showSma && <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"sma-div-" + id}></div>}
            </div>
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            options={options[0]}
            ref={"chart"}
          />
          {/* {
            showRsi &&
            <div className="rsi-graph-div">
              <div className="closing-price-text-div" id="extra-graph-div">
                <div className="graph-value-div">
                  <div onClick={this.onRsiClick} className="rsi-text graph-value indicator-value" id={"rsi-div-" + id}></div>
                </div>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={rsiOptions}
                ref={"chart"}
              />
            </div>
          }
          {
            showRsp &&
            <div className="rsi-graph-div">
              <div className="closing-price-text-div" id="extra-graph-div">
                <div className="graph-value-div">
                  <div className="rsp-text graph-value indicator-value" id={"rsp-div-" + id}></div>
                </div>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={rspOptions}
                ref={"chart"}
              />
            </div>
          }
          {
            showCorrelation &&
            <div className="rsi-graph-div">
              <div className="closing-price-text-div" id="extra-graph-div">
                <div className="graph-value-div">
                  <div className="corr-text graph-value indicator-value" id={"corr-div-" + id}></div>
                </div>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={correlationOptions}
                ref={"chart"}
              />
            </div>
          } */}
        </div>
        {/* <hr className="divider-chart" /> */}

        {showViewButton && 
          <>
            <hr className="divider-chart"/>
            <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div" style={{marginTop: "4rem"}}>
                  <div className="closing-price-text-div">
                    <div className="graph-value-div">
                      <div className="closing-price-text graph-value" id={"vvol-div-" + id}>VVOL</div>
                      <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"vvol-sma-div-" + id}></div>
                    </div>
                  </div>

              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={options[1]}
              />
            </div>
            <hr className="divider-chart" />

            <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div" style={{marginTop: "4rem"}}>
                  <div className="closing-price-text-div">
                    <div className="graph-value-div">
                      <div className="closing-price-text graph-value" id={"vflow-div-" + id}>VFLOW</div>
                      <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"vflow-sma-div-" + id}></div>
                    </div>
                  </div>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={options[2]}
              />
            </div>
          </>
        }
        {
          !showViewButton &&
          <div>
            {/* <hr className="divider-chart" /> */}
            {/* indicator 3,4,5 */}
            {/* <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div">
                  <div>
                    <div className="closing-price-text-div">
                      <div className="graph-value-div">
                        <div className="closing-price-text graph-value" id={"indicator3-div-" + id}>Indicator 3</div>
                        <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"indicator3-sma-div-" + id}></div>
                      </div>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={options[3]}
                    />
                  </div>
            </div>
            <hr className="divider-chart" />
            <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div">
                  <div>
                    <div className="closing-price-text-div">
                      <div className="graph-value-div">
                        <div className="closing-price-text graph-value" id={"indicator5-div-" + id}>Indicator 5</div>
                        <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"indicator5-sma-div-" + id}></div>
                      </div>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={options[4]}
                    />
                  </div>
            </div>
            <hr className="divider-chart" />
            <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div">
                  <div>
                    <div className="closing-price-text-div">
                      <div className="graph-value-div">
                        <div className="closing-price-text graph-value" id={"indicator6-div-" + id}>Indicator 6</div>
                        <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={"indicator6-sma-div-" + id}></div>
                      </div>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={options[5]}
                    />
                  </div>
            </div> */}
            {this.state.selectedValues && this.state.selectedValues.map((val, index) => (
              //val has label (RSI) and value (showRsi)
              // <div>{val.label}</div>
              <>
                <hr className="divider-chart" />
                <div className="p-l-20 p-r-20 p-b-20" id="vvol-chart-div">
                    <div>
                      <div className="closing-price-text-div">
                        <div className="graph-value-div">
                          <div className="closing-price-text graph-value" id={val.classPrefix + "-div-" + id}>{val.label}</div>
                          {showSma && <div onClick={this.onValueClick} className="sma-text graph-value indicator-value" id={val.classPrefix + "-sma-div-" + id}></div>}
                          {showEma && <div onClick={this.onValueClick} className="ema-text indicator-value" id={val.classPrefix + "-ema-div-" + id}></div>}
                        </div>
                      </div>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={options[val.index]}
                      />
                      
                    </div>
                </div>
              </>
            ))}
          </div>}
      </>
      : <div className="no-chart-data">
        <h6 className="no-chart-data-text">Chart Data Not Available</h6>
      </div>

    return (
      <>
        <div className="white-box p-0" id={showViewButton ? '' : 'full-view-title'}>
          <div>
            <h3
              className="box-title chart-title p-t-10 p-b-10 p-l-30 p-r-30" id="chart-header">
              {!showViewButton
                &&
                <span style={{ cursor: 'pointer' }} onClick={this.goBack}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  {title}
                </span>
              }
              {/* {title} */}
              {
                showViewButton &&
                <div className="tooltipx">
                <span onClick={this.onFullView} style={{ fontSize: '12px', marginLeft: '15px', cursor: 'pointer' }}>
                  Technical Analysis
                  <i className="fa fa-external-link external-link-img"></i>
                </span>
                <div className="tooltiptext-div">{getTooltipText("technicalAnalysis")}</div>
                </div>
              }

              {
                (id === 'assetChartId' || id === 'assetChart') &&
                <div className="period-dropdown">
                  <div className="tooltipx">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {this.state.periodValue}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '0', '1M')}>1M</Dropdown.Item>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '1', '3M')}>3M</Dropdown.Item>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '2', '6M')}>6M</Dropdown.Item>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '3', 'YTD')}>YTD</Dropdown.Item>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '4', '1Y')}>1Y</Dropdown.Item>
                      <Dropdown.Item onClick={this.onPeriodClick.bind(this, '5', 'All')}>All</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="tooltiptext-div">{getTooltipText("period")}</div>
                  </div>
                </div>
              }

              <button
                onClick={this.onResetZoom.bind(this)}
                className="btn btn-outline waves-effect waves-light" id="reset-zoom-btn">
                Reset Zoom
              </button>

              {
                !showViewButton &&
                <div className="indicator-dropdown">
                  <Select
                    className="react-select-container select-indicators"
                    // sx={{width:"50% !important"}}
                    onChange={this.onValueSelect}
                    options={indicatorOptions}
                    isMulti={true}
                    isSearchable={true}
                    placeholder='Select Technical Indicators'
                  />
                  {/* <ToggleButtonGroup
                    value={formats}
                    onChange={handleFormat}
                    aria-label="indicator options"
                  > */}
                  <div className="indicator-options">
                    {/* TODO: figure out how to do this in toggleButtonGroup to simplify css */}
                  {/* <ToggleButtonGroup onChange={() => console.log()}> */}
                    <ToggleButton 
                      value="sma"
                      className="indicator-toggle"
                      style={{borderRadius: "5px 0 0 5px"}}
                      selected={this.state.showSma}
                      onChange={() => this.setState((prevState) => {return {...prevState, showSma: !prevState.showSma}})}
                      aria-label="show-sma">
                      <Typography>SMA</Typography>
                    </ToggleButton>
                    <ToggleButton 
                      value="ema"
                      className="indicator-toggle"
                      style={{borderRadius: "0px", borderWidth: "1px 0 1px 0"}}
                      selected={this.state.showEma} 
                      onChange={() => this.setState((prevState) => {return {...prevState, showEma: !prevState.showEma}})}
                      aria-label="bold">
                      <Typography>EMA</Typography>
                    </ToggleButton>
                    {/* <ToggleButton 
                      value="macd"
                      className="indicator-toggle"
                      style={{borderRadius: "0px", borderWidth: "1px 0 1px 1px"}}
                      selected = {this.state.showMacd} 
                      onChange={() => this.setState((prevState) => {return {...prevState, showMacd: !prevState.showMacd}})}
                      aria-label="bold">
                      <Typography>MACD</Typography>
                    </ToggleButton> */}
                    <ToggleButton 
                      value="bollinger"
                      className="indicator-toggle"
                      style={{borderRadius: "0 5px 5px 0", borderWidth: "1px 1px 1px 1px"}}
                      selected={this.state.showBB} 
                      onChange={() => this.setState((prevState) => {return {...prevState, showBB: !prevState.showBB}})}
                      aria-label="bold">
                      <Typography>Bollinger Bands</Typography>
                    </ToggleButton>
                    {/* </ToggleButtonGroup> */}
                  </div>
                  {/* </ToggleButtonGroup> */}
                </div>
              }

              <DatePicker
                onChange={this.onDateChange}
                maxDate={maxDate}
                minDate={minDate}
                value={new Date(this.state.chartDate)}
                isOpen={calendarOpen}
                onCalendarClose={() => { this.setState({ calendarOpen: false }) }}
              />
            </h3>
            {/* <div className="tooltipx"> */}
            <div
              onClick={() => { this.setState({ calendarOpen: !this.state.calendarOpen }) }}>
              <h5
                className="series-date"
                id={"series-date" + id}
              >
                {this.state.date}
              </h5>
            </div>
            {/* <div className="tooltiptext-div">{getTooltipText("chartDate")}</div>
            </div> */}
          </div>

          <div id={"synch-chart-container" + id} className="chart-container-div">
            {chartView}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioAssetChartData: state.PortfolioReducer.portfolioAssetChartData
});

export default connect(mapStateToProps)(SynchChart)
